<template>
  <PageTemplate>
		<Section>
			<Grid>
				<GridContainer size="6">
					<Card>
						<CardSection collapse>
							<Images height="250px" width="100%" image="theme-ed/blog1.jpg" />
						</CardSection>
						<CardSection>
							<Stack direction="column" space="3" align="left">
								<Heading size="6" color="primary">Performance, SEO</Heading>
								<Stack space="2">
									<Icon icon="date_range" size="small" color="primary" />
									<Heading size="6" uppercase>9 January 2022</Heading>
									<Heading size="6" uppercase>By Admin</Heading>
								</Stack>
								<Heading size="4">Melancholy particular devonshire alter</Heading>
								<Paragraph micro>Ut id erat scelerisque, vulputate ligula aliquet, condimentum mauris. Suspendisse efficitur est in quam lobortis convallis…</Paragraph>
								<Button label="Read More" color="primary" type="outline" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="6">
					<Card>
						<CardSection collapse>
							<Images height="250px" width="100%" image="theme-ed/blog2.jpg" />
						</CardSection>
						<CardSection>
							<Stack direction="column" space="3" align="left">
								<Heading size="6" color="primary">Affiliate, Process</Heading>
								<Stack space="2">
									<Icon icon="date_range" size="small" color="primary" />
									<Heading size="6" uppercase>5 January 2022</Heading>
									<Heading size="6" uppercase>By Admin</Heading>
								</Stack>
								<Heading size="4">Future plan &amp; strategy for construction</Heading>
								<Paragraph micro>Ut id erat scelerisque, vulputate ligula aliquet, condimentum mauris. Suspendisse efficitur est in quam lobortis convallis…</Paragraph>
								<Button label="Read More" color="primary" type="outline" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="6">
					<Card>
						<CardSection collapse>
							<Images height="250px" width="100%" image="theme-ed/blog3.jpg" />
						</CardSection>
						<CardSection>
							<Stack direction="column" space="3" align="left">
								<Heading size="6" color="primary">Affiliate, Analysis</Heading>
								<Stack space="2">
									<Icon icon="date_range" size="small" color="primary" />
									<Heading size="6" uppercase>2 January 2022</Heading>
									<Heading size="6" uppercase>By Admin</Heading>
								</Stack>
								<Heading size="4">Participate in staff meetingness manage</Heading>
								<Paragraph micro>Ut id erat scelerisque, vulputate ligula aliquet, condimentum mauris. Suspendisse efficitur est in quam lobortis convallis…</Paragraph>
								<Button label="Read More" color="primary" type="outline" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
				<GridContainer size="6">
					<Card>
						<CardSection collapse>
							<Images height="250px" width="100%" image="theme-ed/blog4.jpg" />
						</CardSection>
						<CardSection>
							<Stack direction="column" space="3" align="left">
								<Heading size="6" color="primary">Analysis, SEO</Heading>
								<Stack space="2">
									<Icon icon="date_range" size="small" color="primary" />
									<Heading size="6" uppercase>1 January 2022</Heading>
									<Heading size="6" uppercase>By Admin</Heading>
								</Stack>
								<Heading size="4">Discovery incommode earnestly command</Heading>
								<Paragraph micro>Ut id erat scelerisque, vulputate ligula aliquet, condimentum mauris. Suspendisse efficitur est in quam lobortis convallis…</Paragraph>
								<Button label="Read More" color="primary" type="outline" />
							</Stack>
						</CardSection>
					</Card>
				</GridContainer>
			</Grid>
		</Section>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/projects/edison/template/Page'; 
export default {
  components: {
		PageTemplate
  },
	props: {
		theme: String
	},
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
}

</script>

<style lang="scss">
</style>
